// import './pop-up.scss'
import React, { useState, useEffect, useRef } from 'react'
import { toastr } from 'react-redux-toastr'
import { useHistory } from 'react-router'
import { IonInput, IonItem, IonList } from '@ionic/react'
// import ReCAPTCHA from 'react-google-recaptcha'
import closeLogin from '../../../assets/images/closeLogin.svg'
import { connectLoginPopUp } from '../loginPopUp.connect'
// import GoogleIcon from './google-icon.svg'
import { useSelector } from 'react-redux'

const LogInStep = ({
  open,
  onDidDismiss,
  recaptchaResponseProps,
  actions,
  B2C,
  changeTab,
  width,
  focus,
  setFocus,
}) => {
  const refRecaptcha = useRef(null)
  const history = useHistory()
  let [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingDemo, setLoadingDemo] = useState(false)

  const [passwordRules, setPasswordRules] = useState({
    length: false,
    specChar: false,
    capitalLetter: false,
    digit: false,
    password: '',
  })
  const [showPasswordRules, setShowPasswordRules] = useState(false)
  const [allRulesChecked, setAllRulesChecked] = useState(false)
  // const [googleButtonWrapper, setGoogleButtonWrapper] = useState()
  // const [googleLoginWrapper, setGoogleLoginWrapper] = useState()
  const logo = useSelector((state) => state.logo)

  const getBalance = (memberCode) => {
    //TODO: change balance request
    // actions
    //   .requestBalance(memberCode)
    //   .then((res) => {
    //     if (B2C && res.data.result.availBalance < 100) {
    //       // history.push("/deposit")
    //     }
    //   })
    //   .catch((e) => console.log('balance error = ', e))
  }

  useEffect(() => {
    // setGoogleLoginWrapper(createGoogleWrapper());
  }, [])

  useEffect(() => {
    if (
      !passwordRules.length ||
      !passwordRules.specChar ||
      !passwordRules.capitalLetter ||
      !passwordRules.digit
    ) {
      if (passwordRules.password) {
        setShowPasswordRules(true)
        setAllRulesChecked(false)
      }
    } else {
      setShowPasswordRules(false)
      setAllRulesChecked(true)
    }
  }, [passwordRules])

  const [userErr, setUserErr] = useState('')
  const [passErr, setPassErr] = useState('')
  const validate = () => {
    if (!username) {
      setUserErr('This field is required')
    }
    if (!password) {
      setPassErr('This field is required')
    }
  }

  const submit = async () => {
    // LOGIN API CHANGE

    try {
      validate()
      if (!username || !password || loading) return
      setLoading(true)
      // const recaptchaResponse =
      //   await recaptchaResponseProps.current.executeAsync()
      // console.log('recaptchaResponse11', recaptchaResponse)
      // let newUserName = ''
      let mockData = {
        user_name: 'lcdemo01',
        password: 'Abcd12345',
        grant_type: 'password',
      }
      let data = {
        user_name: username.toLowerCase(),
        password: password,
        grant_type: 'password',
      }
      // newUserName = selectedType == 'mob' ? `91_${username}` : username
      // if (B2C) {
      await actions
        .requestAuth(data)
        // .requestAuth({
        //   username: newUserName,
        //   password,
        //   recaptchaResponse,
        //   answer: '',
        //   token: '',
        // })

        .then((res) => {
          setLoading(false)
          // getBalance(res.data.result.memberCode)
          onDidDismiss()
          // window.location.reload()
        })
      // } else {
      //   await actions
      //     .requestAuth({
      //       username,
      //       password,
      //       recaptchaResponse,
      //       answer: '',
      //       token: '',
      //     })
      //     .then((res) => {
      //       setLoading(false)
      //     })
      // }
    } catch (e) {
      setLoading(false)
      if (!show) {
        setShow(true)
      }
      toastr.error('', `${e.msg}`)
      // if (refRecaptcha.current) refRecaptcha.current.reset()
    }
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      submit()
    }
  }

  const changeName = (e) => {
    const value = e?.target?.value
    setUserErr('')
    //TODO: add validation rules

    // if (selectedType == 'mob') {
    //   if (value?.length <= 10) {
    //     e.target.value = value?.replace(/[^\d]/g, '')
    //     setUsername(e.target.value)
    //   } else {
    //     e.target.value = username
    //   }
    //   return
    // }
    // if (selectedType == 'user') {
    //     setUsername(value)
    //   return
    // }
    setUsername(value)
    return

    // if (e?.target?.value?.length <= 10) {
    //   setUsername(e.target.value?.replace(/\D/g, ''))
    // } else if (selectedType == 'mob') {
    //   if(e?.target?.value) e.target.value = username?.replace(/\D/g, '')
    // }
  }

  const changePass = (e) => {
    setPassErr('')
    if (e?.target?.value?.length <= 16) {
      setPassword(e.target.value)
    } else {
      if (e?.target?.value) e.target.value = password
    }
  }

  // update pop-up
  const [isOpen, setIsOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [selectedType, selectType] = useState('mob')
  const [showSelection, setSelection] = useState(false)

  const showHide = () => {
    setShow(!show)
  }
  const onfocusInput = () => {
    setFocus(true)
  }
  const onBlurInput = () => {
    setFocus(false)
  }

  const [focusedInput, setFocusedInput] = useState({})

  const handleFocus = (name) => {
    setFocusedInput((prevState) => ({ ...prevState, [name]: true }))
  }

  const handleBlur = (name) => {
    setFocusedInput((prevState) => ({ ...prevState, [name]: false }))
  }

  const handleSelect = (type) => {
    setSelection(false)
    selectType(type)
  }

  const createGoogleWrapper = () => {
    // window.google.accounts.id.initialize({
    //   client_id: "454874373641-aaiuk6rbot84klbhbeduicgddbguvho5.apps.googleusercontent.com",
    //   callback: async (response) => {
    //     const token = response.credential;
    //     const recaptchaResponse = await recaptchaResponseProps.current.executeAsync();
    //     await actions
    //       .requestAuthGoogle({
    //         recaptchaResponse,
    //         token: token,
    //       })
    //       .then((response) => {
    //         window.srtmCommands.push({
    //           event: "track.user.login",
    //           payload: {
    //             action: "complete",
    //             userId: response.data.result.memberCode
    //           }
    //         });
    //         setLoading(false);
    //       });
    //   },
    //   auto_select: false,
    //   ux_mode: "popup",
    // })
    // const btn = document.createElement('div');
    // btn.style.display = 'none';
    // btn.classList.add('custom-google-button');
    // document.body.appendChild(btn);
    // window.google.accounts.id.renderButton(btn, {});
    // const googleLoginWrapper = btn.querySelector("div[role=button]");
    // setGoogleButtonWrapper(btn.querySelector("div[role=button]"))
    // return {
    //   click: () => {
    //     googleLoginWrapper.click();
    //   }
    // }
  }

  const glogin = async () => {
    // googleButtonWrapper.click();
    // googleLoginWrapper.click();
  }

  useEffect(() => {
    setUsername('')
  }, [selectedType])

  const reqDemo = async () => {
    try {
      setLoadingDemo(true)
      await actions.requestAuthDemo().then((res) => {
        let data = {
          user_name: res?.data?.data?.user_name,
          password: res?.data?.data?.password,
          grant_type: 'password',
        }
        actions
          .requestAuth(data)
          .then(() => {
            setLoadingDemo(false)
            onDidDismiss()
          })
          .catch((e) => {
            setLoadingDemo(false)
            toastr.error('', `${e.msg}`)
          })
      })
    } catch (e) {
      setLoadingDemo(false)
      toastr.error('', `${e.msg}`)
    }

    // actions.requestAuthDemo()
    // requestAuth(response?.data.data)
  }

  // close time dropdown after click somewhere
  const contentDateRef = useRef(null)
  const closeOptions = (e) =>
    !e.path?.includes(contentDateRef.current) ? setSelection(false) : ''
  const optionsRef = useRef()

  useEffect(() => {
    if (showSelection) {
      window.addEventListener('click', closeOptions)
      return () => window.removeEventListener('click', closeOptions)
    }
    return () => window.removeEventListener('click', closeOptions)
  }, [showSelection])

  return width > 1024 ? (
    <>
      {
        <div className="loginPopUpContainer-login">
          {B2C ? (
            <IonList class="login-content__list">
              <input
                className="loginInput"
                formcontrolname="username"
                placeholder={
                  focusedInput['username']
                    ? ''
                    : selectedType == 'mob'
                    ? '10 Digit Phone Number'
                    : 'ID'
                }
                onClick={() => handleFocus('username')}
                onBlur={() => handleBlur('username')}
                name="username"
                type={selectedType == 'mob' ? 'tel' : 'text'}
                value={`${username?.replace(' ', '')}`}
                autoComplete="username"
                onChange={changeName}
                onIonChange={changeName}
                hidden
              />
              <input
                formcontrolname="password"
                placeholder={focusedInput['password'] ? '' : 'Password'}
                onClick={() => handleFocus('password')}
                onBlur={() => handleBlur('password')}
                className="loginInput"
                onInput={(e) => (e.target.value = e.target.value.slice(0, 16))}
                name="password"
                type="password"
                value={password}
                autoComplete="current-password"
                onChange={(e) => changePass(e.target.value)}
                hidden
              />
              <div className="b2c_login">
                <IonItem className="overflow-visible">
                  {/* {selectedType == 'mob' ? (
                    <div className="placeholder">+91</div>
                  ) : (
                    ''
                  )} */}
                  <input
                    formcontrolname="username"
                    className={`loginInput ${
                      selectedType == 'user' ? 'remove-pad-start' : ''
                    }`}
                    placeholder={
                      focusedInput['username'] ? '' : 'Enter Username'
                    }
                    onClick={() => handleFocus('username')}
                    onBlur={() => handleBlur('username')}
                    name="username"
                    // type={selectedType == 'mob' ? 'tel' : 'text'}
                    autoComplete="username"
                    value={`${username?.replace(' ', '')}`}
                    onKeyDown={onKeyDown}
                    // onChange={changeName}
                    onChange={changeName}
                    onIonChange={changeName}
                  />

                  <div className={`login_icons`}>
                    {/* <div onClick={() => setSelection(true)} className={'icon'}>
                      <div className={`selected-icon ${selectedType}`}></div>
                      <div className="arrow-icon"></div>
                    </div> */}

                    <div
                      ref={optionsRef}
                      className={`option-menu ${
                        showSelection ? 'show' : 'hide'
                      }`}
                    >
                      <div
                        onClick={() => handleSelect('mob')}
                        className={`option-item ${
                          selectedType == 'mob' ? 'selected' : ''
                        }`}
                      >
                        <div className="mob-icon"></div>
                        <div className="title">Mobile Number</div>
                      </div>
                      <div
                        onClick={() => handleSelect('user')}
                        className={`option-item ${
                          selectedType == 'user' ? 'selected' : ''
                        }`}
                      >
                        <div className="user-icon"></div>
                        <div className="title">User ID</div>
                      </div>
                    </div>
                  </div>
                </IonItem>
                <div className="signUpNew_inputContainer_row">
                  <div className="signUpNew_inputContainer_row-error">
                    {userErr}
                  </div>
                </div>

                <IonItem>
                  <IonInput
                    className="loginInput remove-pad-start"
                    formcontrolname="password"
                    placeholder={
                      focusedInput['password'] ? '' : 'Enter Password'
                    }
                    onIonFocus={() => handleFocus('password')}
                    onIonBlur={() => handleBlur('password')}
                    name="password"
                    type={show ? 'text' : 'password'}
                    value={password}
                    autocomplete="current-password"
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 16))
                    }
                    id="current-password"
                    onKeyDown={onKeyDown}
                    onIonChange={changePass}
                    onChange={changePass}
                  />

                  {/*<img className='icons' src={show ? eyeOff : eye }  onClick={showHide} />*/}
                  <div
                    className={`${
                      show ? 'login_icons eyeOff' : 'login_icons eye'
                    } `}
                    onClick={showHide}
                  />
                </IonItem>
                <div className="signUpNew_inputContainer_row">
                  <div className="signUpNew_inputContainer_row-error">
                    {passErr}
                  </div>
                </div>
              </div>
            </IonList>
          ) : (
            <IonList class="login-content__list">
              <input
                className="loginInput"
                formcontrolname="username"
                placeholder={focusedInput['username'] ? '' : 'Login Name'}
                onClick={() => handleFocus('username')}
                onBlur={() => handleBlur('username')}
                name="username"
                type="username"
                value={`${username?.replace(' ', '')}`}
                autoComplete="Phone Number"
                onChange={changeName}
                onIonChange={changeName}
                hidden
              />
              <input
                className="loginInput"
                formcontrolname="password"
                onInput={(e) => (e.target.value = e.target.value.slice(0, 16))}
                placeholder={focusedInput['password'] ? '' : 'Password'}
                onClick={() => handleFocus('password')}
                onBlur={() => handleBlur('password')}
                name="password"
                type="password"
                value={password}
                autoComplete="current-password"
                onChange={(e) => changePass(e.target.value)}
                hidden
              />
              <IonItem>
                <IonInput
                  className="loginInput"
                  formcontrolname="username"
                  placeholder={focusedInput['username'] ? '' : 'Login Name'}
                  onIonFocus={() => handleFocus('username')}
                  onIonBlur={() => handleBlur('username')}
                  name="username"
                  type="username"
                  autoComplete="username"
                  value={`${username?.replace(' ', '')}`}
                  onKeyDown={onKeyDown}
                  onChange={changeName}
                  onIonChange={changeName}
                />
              </IonItem>
              <IonItem>
                <IonInput
                  className="loginInput"
                  formcontrolname="password"
                  placeholder={focusedInput['password'] ? '' : 'Password'}
                  onIonFocus={() => handleFocus('password')}
                  onIonBlur={() => handleBlur('password')}
                  name="password"
                  onInput={(e) =>
                    (e.target.value = e.target.value.slice(0, 16))
                  }
                  type="password"
                  value={password}
                  autoComplete="current-password"
                  onKeyDown={onKeyDown}
                  onIonChange={changePass}
                />
              </IonItem>
            </IonList>
          )}
        </div>
      }
      {B2C && (
        <div
          className="pop-up-content__buttons pop-up-content__buttons-new forgot"
          // onClick={() => actions.setTab(3)}
        >
          {/* <span onClick={() => actions.setTab(3)}>
          Forgot password?
          </span> */}
        </div>
      )}
      <div className="pop-up-content__buttons">
        <div
          onClick={submit}
          className="pushable"
          disabled={userErr || passErr || loading}
        >
          <span className="shadow"></span>
          <span className="edge "></span>
          <span className="front refreshBtn login">
            Log in
            {loading && <span className="loadingLogIn"></span>}
          </span>
        </div>
      </div>
      <div className="registerWith-content">
        <div className="register-chose">Or Log in with</div>
        <br></br>
        <div className="pop-up-content__buttons">
          <div
            onClick={reqDemo}
            className="pushable"
            // disabled={!username || !password || loading}
          >
            <span className="shadow"></span>
            <span className="edge "></span>
            <span className="front refreshBtn login">
              Demo Login
              {loadingDemo && (
                <span className="loadingLogIn loadingLogInDemo"></span>
              )}
            </span>
          </div>
        </div>
        {/* <div className='registerWith-content-btns'>
          <div className="register-button" onClick={() => glogin()}>
              <img className="icon" src={GoogleIcon} alt="" />
              <span className="register-button-link">Google</span>
            </div>
          <div className="register-button" onClick={() =>
              // actions.setTab(2)
              // window.open('https://lotus365.authlink.me')
              window.location.href = `https://lotus365.authlink.me/?redirectUri=http://${window.location.host}`
            }>
              <span >New user? </span>
                <span className="register-button-link">{t('loginAndRegPopUp.registerNow')}</span>
              <img className="icon" src={WhatsappIcon} alt="" />
              <span className="register-button-link">Whatsapp</span>
          </div>
        </div> */}
      </div>
      {/* <div className="pop-up-content__buttons sign"><span>New user? </span><a href="https://www.lotus365.com/?sign"> Register now</a></div> */}
    </>
  ) : (
    <div className={`login-step-wrapper `}>
      <div className="pop-up-content_title" onClick={() => onDidDismiss(false)}>
        <div
          className="pop-up-content_title-logo"
          style={{
            backgroundImage: `url(${logo})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '200px',
            height: '52px',
          }}
        ></div>
        <img src={closeLogin} />
      </div>
      <div className="login-step-content">
        <div className="login-step-content__title">Log in</div>
        {
          <div className="loginPopUpContainer-login">
            {B2C ? (
              <IonList class="login-content__list">
                <input
                  className="loginInput"
                  placeholder={
                    focusedInput['username']
                      ? ''
                      : selectedType == 'mob'
                      ? 'Phone Number'
                      : 'ID'
                  }
                  onClick={() => handleFocus('username')}
                  onBlur={() => handleBlur('username')}
                  name="username"
                  type={selectedType == 'mob' ? 'text' : 'text'}
                  value={`${username?.replace(' ', '')}`}
                  autoComplete="username"
                  // onChange={changeName}
                  onChange={(e) => changeName(e)}
                  onIonChange={changeName}
                  hidden
                />
                <input
                  className="loginInput"
                  formcontrolname="password"
                  placeholder={focusedInput['password'] ? '' : 'Password'}
                  onClick={() => handleFocus('password')}
                  onBlur={() => handleBlur('password')}
                  name="password"
                  onInput={(e) =>
                    (e.target.value = e.target.value.slice(0, 16))
                  }
                  type="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={(e) => changePass(e.target.value)}
                  hidden
                />
                <div className="b2c_login">
                  <IonItem className="login overflow-visible">
                    {/* {selectedType == 'mob' ? (
                      <div className="placeholder">+91</div>
                    ) : (
                      ''
                    )} */}
                    <input
                      className={`loginInput ${
                        selectedType == 'user' ? 'remove-pad-start' : ''
                      }`}
                      formcontrolname="username"
                      placeholder={
                        focusedInput['username'] ? '' : 'Enter Username'
                      }
                      onClick={() => handleFocus('username')}
                      onBlur={() => handleBlur('username')}
                      name="username"
                      type={'text'}
                      autoComplete="username"
                      value={`${username?.replace(' ', '')}`}
                      onKeyDown={onKeyDown}
                      // onChange={changeName}
                      onChange={(e) => changeName(e)}
                      onIonChange={changeName}
                    />
                    {/* <div className={`login_icons`}>
                      <div
                        onClick={() => setSelection(true)}
                        className={'icon'}
                      >
                        <div className={`selected-icon ${selectedType}`}></div>
                        <div className="arrow-icon"></div>
                      </div>

                      <div
                        className={`option-menu ${
                          showSelection ? 'show' : 'hide'
                        }`}
                      >
                        <div
                          onClick={() => handleSelect('mob')}
                          className={`option-item ${
                            selectedType == 'mob' ? 'selected' : ''
                          }`}
                        >
                          <div className="mob-icon"></div>
                          <div className="title">Mobile Number</div>
                        </div>
                        <div
                          onClick={() => handleSelect('user')}
                          className={`option-item ${
                            selectedType == 'user' ? 'selected' : ''
                          }`}
                        >
                          <div className="user-icon"></div>
                          <div className="title">User ID</div>
                        </div>
                      </div>
                    </div> */}
                  </IonItem>
                  <div className="signUpNew_inputContainer_row">
                    <div className="signUpNew_inputContainer_row-error">
                      {userErr}
                    </div>
                  </div>

                  <IonItem className="login">
                    <IonInput
                      className="loginInput  remove-pad-start"
                      formcontrolname="password"
                      placeholder={
                        focusedInput['password'] ? '' : 'Enter Password'
                      }
                      onIonFocus={() => handleFocus('password')}
                      onIonBlur={() => handleBlur('password')}
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 16))
                      }
                      name="password"
                      type={show ? 'text' : 'password'}
                      value={password}
                      autoComplete="current-password"
                      onKeyDown={onKeyDown}
                      onIonChange={changePass}
                    />
                    {/*<img className='icons' src={show ? eyeOff : eye }  onClick={showHide} />*/}
                    <div
                      className={`${
                        show ? 'login_icons eyeOff' : 'login_icons eye'
                      } `}
                      onClick={showHide}
                    ></div>
                  </IonItem>
                  <div className="signUpNew_inputContainer_row">
                    <div className="signUpNew_inputContainer_row-error">
                      {passErr}
                    </div>
                  </div>
                </div>
              </IonList>
            ) : (
              <IonList class="login-content__list">
                <input
                  className="loginInput"
                  formcontrolname="username"
                  placeholder={focusedInput['username'] ? '' : 'Login Name'}
                  onClick={() => handleFocus('username')}
                  onBlur={() => handleBlur('username')}
                  name="username"
                  type={'text'}
                  value={`${username?.replace(' ', '')}`}
                  autoComplete="Phone Number"
                  onChange={changeName}
                  onIonChange={changeName}
                  hidden
                />
                <input
                  className="loginInput"
                  formcontrolname="password"
                  placeholder={focusedInput['password'] ? '' : 'Password'}
                  onClick={() => handleFocus('password')}
                  onBlur={() => handleBlur('password')}
                  name="password"
                  onInput={(e) =>
                    (e.target.value = e.target.value.slice(0, 16))
                  }
                  type="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={(e) => changePass(e.target.value)}
                  hidden
                />
                <IonItem className="login">
                  <IonInput
                    className="loginInput"
                    formcontrolname="username"
                    placeholder={focusedInput['username'] ? '' : 'Login Name'}
                    onIonFocus={() => handleFocus('username')}
                    onIonBlur={() => handleBlur('username')}
                    name="username"
                    type={'text'}
                    autoComplete="username"
                    value={`${username?.replace(' ', '')}`}
                    onKeyDown={onKeyDown}
                    onChange={changeName}
                    onIonChange={changeName}
                  />
                </IonItem>
                <IonItem className="login">
                  <IonInput
                    className="loginInput"
                    formcontrolname="password"
                    placeholder={focusedInput['password'] ? '' : 'Password'}
                    onIonFocus={() => handleFocus('password')}
                    onIonBlur={() => handleBlur('password')}
                    name="password"
                    type="password"
                    onInput={(e) =>
                      (e.target.value = e.target.value.slice(0, 16))
                    }
                    value={password}
                    autoComplete="current-password"
                    onKeyDown={onKeyDown}
                    onIonChange={changePass}
                  />
                </IonItem>
              </IonList>
            )}
          </div>
        }
        {B2C && (
          <div className="forgot-pass-button">
            {/* <span onClick={() => actions.setTab(3)}>Forgot password?</span> */}
          </div>
        )}

        <div className="pop-up-content__buttons">
          <button
            onFocus={submit}
            onClick={submit}
            className="pushable log-in"
            disabled={userErr || passErr || loading}
          >
            <span className="shadow"></span>
            <span className="edge "></span>
            <span className="front refreshBtn">
              Log in
              {loading && <span className="loadingLogIn"></span>}
            </span>
          </button>
        </div>

        <div className="registerWith-content">
          <div className="register-chose">Or Log in with</div>
          <div className="pop-up-content__buttons">
            <button
              // onFocus={reqDemo}
              onClick={reqDemo}
              className="pushable log-in"
            >
              <span className="shadow"></span>
              <span className="edge "></span>
              <span className="front refreshBtn">
                Demo Login
                {loadingDemo && (
                  <span className="loadingLogIn loadingLogInDemo"></span>
                )}
              </span>
            </button>
          </div>
          {/* <div className='registerWith-content-btns'>
          <div className="register-button" onClick={() => glogin()}>
              <img className="icon" src={GoogleIcon} alt="" />
              <span className="register-button-link">Google</span>
            </div>
          <div className="register-button" onClick={() =>
              // actions.setTab(2)
              // window.open(CONFIG.whatsappLink)
              window.location.href = `https://lotus365.authlink.me/?redirectUri=http://${window.location.host}`

            }>
              <span >New user? </span>
                <span className="register-button-link">{t('loginAndRegPopUp.registerNow')}</span>
              <img className="icon" src={WhatsappIcon} alt="" />
              <span className="register-button-link">Whatsapp</span>
          </div>
        </div> */}
          {B2C && (
            <div className="link-text">
              New User?{' '}
              <span
                onClick={() => {
                  actions.setTab(2)
                }}
              >
                Create an account
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default connectLoginPopUp()(LogInStep)
